/* .footer-wrapper
{
    box-shadow: 4px 3px 13px rgba(0, 0, 0, 0.1);
} */
.footer-wrapper p.copyright_text::first-letter
{
    text-transform: capitalize;
}
.footer-wrapper p.copyright_text span
{
    margin-top: -7px;
    font-weight: 800;
}
.footer-wrapper p.copyright_text span pre {
    font-size: 18px;
    line-height: normal;
}
