/* Customer-details-modal-css::Start */
.modal-edit {
    font-size: 22px;
    color: var(--text-dark-gray);
    margin-right: 10px;
}

.modal-button .btn {
    min-width: 140px;
    height: 48px;
    border-radius: var(--radius-30);
}

.modal-button i {
    margin-right: 5px;
}

.customer-details-modal .modal-dialog {
    max-width: 75%;
}

.mobile-input input {
    padding-right: 0px !important;
    padding-left: 15px !important;
    width: calc(100% - 90px);
    position: absolute !important;
    right: 0;
}
.mobile-input .flag-container
{
    width: 80px !important;
}
.mobile-input .intl-tel-input {
    width: 100%;
    display: flex;
    height:42px ;
}
.mobile-input .intl-tel-input .selected-flag .iti-flag {
    left: 7px;
}
 
.mobile-input .intl-tel-input.separate-dial-code .selected-dial-code {
    font-size: 12px !important;
    font-family: Poppins-Medium;

}
.mobile-input .intl-tel-input.separate-dial-code .selected-flag {
    background-color: transparent;
    border: 1px solid var(--border-gray);
    border-radius: var(--radius-10) !important;
    height: 42px;
    width:80px !important;
}
.mobile-input .intl-tel-input .selected-flag .iti-arrow
{
    display:none !important;
}
/* Customer-details-modal-css::End */