/* .header-main
{
    box-shadow: 4px 3px 13px rgba(0, 0, 0, 0.1);
} */
.select-language-custom .language-img img
{
  width:20px;
  margin-right: 10px
}
.select-language-custom  .drawer-link-name
{
   color:var(--main-black) !important;
}
.select-language-custom  .dropdown-menu
{
   min-width: 6rem;
   right: 0;
   left: unset;
}
.select-language-custom .btn
{
   border: 1px solid var(--border-gray);
   border-radius:5px;
}
.select-language-custom .btn i
{
   margin-right: 10px;
}
.select-language-custom .btn:focus
{
  background:transparent !important;
}
.language-icon-main
{
   padding:5px 10px;
}
.select-language-custom .active-lang span
{
   color: var(--main-white) !important;
}