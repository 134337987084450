.main_lang_wrapper .lang_table_wrapper {
    margin-top: 5px;
}

.main_lang_wrapper .lang_table_wrapper thead {
    position: sticky;
    top: -1px;
    background: var(--main-white);
    color: var(--main-black);
    z-index: 1;
}

.main_lang_wrapper .lang_table_wrapper .table-responsive {
    overflow: scroll;
    height: calc(100vh - 20vh);
}

.main_lang_wrapper .lang_table_wrapper table tr th,
.main_lang_wrapper .lang_table_wrapper table tr td {
    vertical-align: middle;
    padding: 10px 7px;
}

.main_lang_wrapper .lang_table_wrapper table tr th:nth-of-type(1),
.main_lang_wrapper .lang_table_wrapper table tr td:nth-of-type(1),
.main_lang_wrapper .lang_table_wrapper table tr td:nth-of-type(2) {
    position: sticky;
    left: 0;
    background: #a9a9a9;
    color: var(--main-black);
}
.main_lang_wrapper .lang_table_wrapper table tr td:nth-of-type(2) {
    left: 40px;
    background-color: #ababab;
}

.main_lang_wrapper .lang_table_wrapper table thead tr th {
    background: #f1f1f1;
}

.main_lang_wrapper .lang_table_wrapper table tr td input {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 10px 5px;
    border-radius: 5px;
    
}

.main_lang_wrapper .lang_table_wrapper table tr:nth-child(even) td input {
    background-color: #F2F2F2;
}

.modal .lang_modal .modal-header .modal-title {
    color: var(--main-black);
}

.modal .lang_modal
{
    background-color: var(--main-white);
}
.modal .lang_modal label {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
}

.modal .lang_modal input {
    min-height: 45px;
    border-radius: 5px;
    border: 1px solid #b4b4b4 !important;
    box-shadow: none;
    margin-bottom: 10px;
    outline: none;
    padding: 5px;
    width: 100%;
}

.modal .lang_modal .modal-footer button {
    box-shadow: none;
}