/* .modal-dialog
{
    width: 700px !important;
    max-width: 700px !important;
} */
.data-time-icon .view-pdf-btn i{
    background-color: var(--main-white);
    border: 1px solid var(--thm-color-red);
    height: 25px;
    width: 25px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color:  var(--thm-color-red);
    cursor: pointer;
    border-radius: 5px;
}